import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/dobry-crm.jpg";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "dobry-crm-quickcrm-768x455.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "dobry-crm-raport.png" }) {
      childImageSharp {
        fluid(maxWidth: 669, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const DobryCRM = ({ data }) => {
  return (
    <Artykul
      title="Jak dobry CRM może pomóc Ci w zdobywaniu nowych klientów?"
      articleImage={obrazekArtykulu}
      keywords={["dobry CRM"]}
      articleImageAlt="dobry CRM dla małych firm"
      metaTitle="Dobry CRM i jego rola w powiększaniu bazy klientów"
      metaDescription="✅ Jaka jest rola CRM w powiększaniu bazy klientów? Sprawdzamy, czy dobry CRM może pomóc zdobyć klienta i jego zaufanie. QuickCRM - 30 dni za darmo!"
    >
      <br />
      <h2>Dobry CRM i jego rola w powiększaniu bazy klientów.</h2>

      <p>
        <strong>
          Gdzie szukać wsparcia przy procesie pozyskiwania klientów dla nowych
          produktów i usług? Jak budować ich zaufanie i skutecznie zarządzać ich
          satysfakcją?
        </strong>
        <br />
        <br />
        Poszukiwanie klientów i zdobycie ich zaufania jest ważnym zadaniem. Na
        nowoczesnym rynku, który okazuje się być bardzo konkurencyjny, wielu
        przedsiębiorców poszukuje rozwiązań, które pomogą szybko i sprawnie
        powiększyć liczbę odbiorców produktów i usług. Jednym z potencjalnych
        sprzymierzeńców w tym zakresie jest system do zarządzania relacjami z
        klientem.
      </p>
      <h3>Dobry CRM to nie jest zwykła baza danych</h3>
      <p>
        Często niesłusznie uważa się, że systemy CRM to tak naprawdę tylko bazy
        danych. Dobre oprogramowanie CRM nie kończy się na miejscu, do którego
        wprowadzasz dane. Liczy się przede wszystkim sposób, w jaki te dane są
        przetwarzane i udostępniane, a także elastyczność danego systemu.
        <br />
        <br />
        <cite>
          Elastyczny system CRM - to taki, który pozostawia użytkownikowi pole
          do decyzji co do danych, jakie zbiera oraz sposobów klasyfikowania
          tych danych. Dobry CRM powinien także umożliwiać segmentację klientów.
        </cite>
        <br />
        <br />
        To oznacza, że dobry CRM to taki, który nie ogranicza użytkownika co do
        zakresu i rodzaju wprowadzanych danych. Często zdarza się, że prosty
        system CRM (lub tzw. CRM dla małej firmy) narzuca określone formaty lub
        oznaczenia, co może sprawiać, że firma nie odnajdzie tam odniesienia do
        rzeczywistych procesów w niej zachodzących.
      </p>
      <Img
        style={{
          width: "0 auto",
          margin: "0 auto"
        }}
        title="dobry crm quickcrm"
        alt="dobry crm quickcrm"
        fluid={data.zdjecie1.childImageSharp.fluid}
      />

      <h3>Pomaga skupić się na potrzebach potencjalnych klientów</h3>
      <p>
        Dział sprzedaży powinien w pierwszej kolejności dbać o potrzeby
        klientów. Powinien proponować im produkty, które najlepiej je
        zaspokajają.
        <br />
        <br />
        Dobry CRM może pomóc identyfikować profile klientów i poszukiwać nowych
        perspektyw sprzedażowych w związku z nimi. Spójrz: jeśli nie wiesz, kim
        są Twoi aktualni klienci i jakie są ich potrzeby - nie dowiesz się jak
        znaleźć większą grupę odbiorców. Systemy CRM pozwalają na zbieranie
        danych o potrzebach i oczekiwaniach klientów, a także przyczynach
        rezygnacji ze współpracy.
        <br />
        <br />
        Dzięki nim wiesz, jak budować Twoją ofertę i kim jest Twój potencjalny
        klient.
        <br />
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="dobry crm raport"
          alt="dobry crm raport"
          fluid={data.zdjecie2.childImageSharp.fluid}
        />
      </p>
      <h3>Pomaga uporządkować chaos</h3>
      <p>
        Podczas powiększania bazy swoich klientów warto także zadbać o porządek.
        To ważna funkcja systemu CRM, która leży u podstaw zarządzania
        relacjami.
        <br />
        <br />
        Systemy CRM zbierają informacje o wszystkich klientach, którzy
        kontaktowali się z Twoją firmą. Nie ma znaczenia czy dana osoba
        skorzystała z Twojej oferty, czy może udała się do konkurencji, albo
        zrezygnowała z zakupu z jakiegoś powodu. Każda taka informacja jest na
        wagę złota, a handlowiec musi mieć dobre narzędzie do panowania nad
        nimi.
        <br />
        <br />
        Dobry CRM porządkuje tą wiedzę, ale to nie wszystko. Pomaga również
        prowadzić sprzedaż, ponieważ prowadzi handlowca od pierwszego kontaktu
        aż do finalnej transakcji, ukazując mu kroki, które musi wykonać aby
        osiągnąć sukces. Dowiedz się więcej o 👉🏼{" "}
        <Link to="/sprzedaz-w-crm/">szansach sprzedaży</Link> i 👉🏼{" "}
        <Link to="/kartoteki-kontrahentow/">bazie wiedzy o klientach.</Link>
      </p>
      <Link to="/demo-crm/">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="Testuj dobry CRM"
          fluid={data.zdjecie5.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default DobryCRM;
